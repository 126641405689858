import useSWR from 'swr';
import { fetcher } from 'lib/swr';

export const usePersona = () => {
  const { data = {}, error } = useSWR(`${process.env.URL_WWW}/api/persona`, fetcher);

  return {
    data,
    loading: !error && !data,
    error: error,
  };
};

import { breakpoint } from '@layerise/design-core';
import { IconArrowLeft } from '@layerise/design-icon';
import { useHistory } from 'contexts/History';
import { hideLoader } from 'lib/loader';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ReactNode, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Logo as LogoBase } from 'components/Logo';

const Logo = styled(LogoBase)`
  display: block;
  margin: 0 auto 62px;
  width: 160px;
  height: 39px;
`;

const Container = styled.div`
  background-color: #fff;
  display: flex;
  justify-content: center;

  button {
    background-color: #0b1223;
    &:not([disabled]):hover {
      background-color: #2b2e33;
    }
  }

  ${breakpoint.md`
    background-color: #f7f7fa;
    align-items: center;
    height: 100vh;
  `}
`;

const Header = styled.div`
  margin-bottom: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Back = styled.div`
  box-shadow: var(--depth-Z100);
  height: 32px;
  width: 32px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  cursor: pointer;
  svg {
    height: 16px;
    width: 16px;
  }
  svg path {
    fill: var(--color-N400);
  }

  &:hover {
    box-shadow: var(--depth-Z200);
    svg path {
      fill: var(--color-N600);
    }
  }
`;
const ContainerPlacement = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 980px;
  padding: 24px;
  max-height: 100%;
`;

const FormContent = styled.div<{ $textCenter?: boolean }>`
  padding: 75px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${props =>
    props.$textCenter &&
    css`
      text-align: center;
    `}
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 8px;
`;
const Description = styled.p`
  font-size: 16px;
  margin-bottom: 16px;
  color: #0b1223bd;
`;

const CardContent = styled.div`
  display: flex;
`;

const InnerFormContainer = styled.div`
  width: 368px;
  max-width: 100%;
`;

const LinkStyle = styled.p`
  font-size: 12px;
  a {
    cursor: pointer;
    margin-left: 4px;
  }
`;

const Footer = styled.div`
  margin: 48px auto 0px auto;
  max-width: 360px;
  text-align: center;
  height: 36px;
`;

const Card = styled.div`
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  ${breakpoint.md`
  box-shadow: var(--depth-Z100);
  border-radius: 7px;
  `}
`;

export const Form = styled.form`
  width: 100%;
  fieldset {
    border: none;
  }
`;

export const FormContainer = styled.div`
  display: grid;
  grid-auto-rows: auto;
  grid-gap: 16px;
  margin-bottom: 20px;
`;

export const ErrorMessage = styled.span`
  color: #ff3333;
  font-size: 12px;
  letter-spacing: -0.01em;
  line-height: 1.2;
`;

interface AuthProps {
  title?: string;
  linkTitle?: string;
  linkHref?: string;
  linkDescription?: string;
  terms?: boolean;
  description?: ReactNode;
  children?: any;
  textCenter?: boolean;
  onBack?(): void;
}

export const AuthWrapper: React.FC<AuthProps> = ({
  children,
  title,
  linkTitle,
  linkHref,
  linkDescription,
  terms,
  description,
  textCenter,
  onBack,
}) => {
  const { history } = useHistory();
  const router = useRouter();
  const platformBack = history.length > 1;

  useEffect(() => {
    hideLoader();
  }, []);

  return (
    <Container>
      <ContainerPlacement>
        <Header>
          <Link
            href="/"
            onClick={e => {
              e.preventDefault();
              if (onBack) {
                onBack();
                return;
              }

              if (platformBack) {
                router.back();
              } else {
                window.location.href = process.env.URL_WWW as string;
              }
            }}>
            <Back>
              <IconArrowLeft />
            </Back>
          </Link>
          {linkHref && (
            <LinkStyle>
              {linkDescription}
              <Link href={linkHref}>{linkTitle}</Link>
            </LinkStyle>
          )}
        </Header>
        <Card>
          <CardContent>
            <FormContent $textCenter={textCenter}>
              <InnerFormContainer>
                <Logo />
                <Title>{title}</Title>
                <Description>{description}</Description>
              </InnerFormContainer>
              {children}
            </FormContent>
          </CardContent>
        </Card>
        <Footer>
          {terms && (
            <LinkStyle>
              By clicking “Create account” you agree to Layerise’s{' '}
              <Link href={`${process.env.URL_WWW}/legal/terms`}>Terms of Service</Link> and{' '}
              <Link href={`${process.env.URL_WWW}/legal/privacy`}>Privacy Policy</Link>
            </LinkStyle>
          )}
        </Footer>
      </ContainerPlacement>
    </Container>
  );
};

import { useCallback } from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import { flags } from '@layerise/design-flag';
import { Button, useForm, PhoneInput } from '@layerise/design-core';
import { AuthWrapper, ErrorMessage, Form, FormContainer } from 'components/auth/AuthWrapper';
import { useCountries } from 'hooks/data/useCountries';
import { SMS_CODE_LENGTH } from './constants';
import format from 'date-fns/format';
import addDays from 'date-fns/addDays';

const SkipActionContainer = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
  margin-top: 64px;
  font-size: 14px;
  line-height: 1.3;
`;

const Notice = styled.p`
  opacity: 0.74;
  color: #0b1223;
  font-size: 12px;
  line-height: 1.2;
  text-align: center;
`;

const DateInfo = styled.span`
  white-space: nowrap;
`;

const PhoneInputContainer = styled.div`
  margin: 16px 0;
`;

type FormInputs = {
  telCode: string;
  tel: string;
};

type Props = {
  form: {
    inputs: FormInputs;
    handleChange: ReturnType<typeof useForm>['handleChange'];
    setInputs(obj: Partial<FormInputs>): void;
  };
  onSubmit(): void;
  onSkip(): void;
  onBack(): void;
  loading?: boolean;
  errorMessage?: string;
  skippable?: boolean;
};

export function TwoFactorPhoneView({ form, loading, errorMessage, onSubmit, onSkip, onBack, skippable }: Props) {
  const countries = useCountries();

  const { setInputs } = form;

  const handlePhoneChange = useCallback(
    (data: { code: string; phone: string }) => {
      setInputs({
        telCode: data.code,
        tel: data.phone,
      });
    },
    [setInputs]
  );

  return (
    <AuthWrapper
      textCenter
      onBack={onBack}
      title="Two-Factor authentication"
      linkTitle="Contact support"
      linkHref="mailto:cs@layerise.com"
      description={`Enter your mobile phone number. We will send you a ${SMS_CODE_LENGTH}-digit security code to verify your phone`}>
      <Form
        method="post"
        noValidate
        onSubmit={e => {
          e.preventDefault();
          onSubmit();
        }}>
        <fieldset disabled={loading} aria-busy={loading}>
          <FormContainer>
            <PhoneInputContainer>
              <PhoneInput
                flags={flags}
                countries={countries}
                onChange={handlePhoneChange}
                placeholder="Phone number"
                value={{ code: form.inputs.telCode, phone: form.inputs.tel }}
              />
            </PhoneInputContainer>
            <Button
              variant="primary"
              size="lg"
              wide
              disabled={loading || !form.inputs.tel}
              loading={loading}
              type="submit">
              Send code
            </Button>
            <ErrorMessage>{errorMessage ? errorMessage : '‎'}</ErrorMessage>
          </FormContainer>
        </fieldset>
        {skippable ? (
          <SkipActionContainer>
            <Link
              href="#"
              onClick={e => {
                e.preventDefault();
                onSkip();
              }}>
              Skip for now
            </Link>
            <Notice>
              Two-factor Authentication will be required
              <br /> after{' '}
              <DateInfo>
                {format(addDays(new Date(process.env.LAYERISE_2FA_RELEASE_DATE as string), 60), 'MMMM do yyyy')}
              </DateInfo>
            </Notice>
          </SkipActionContainer>
        ) : null}
      </Form>
    </AuthWrapper>
  );
}
